<style scoped lang="less"></style>

<template>
  <PageBase
    title="药品基础数据"
    :tabs="types"
    @tab-switch="onTabSwitch"
    :page-actions="pageActions"
    @page-action="pageAction"
    @page-change="onPageChange"
    :dataList="datas"
    :table-columns="tableColumns"
    :table-actions="tableActions"
    @table-action="tableAction"
    :filters="filters"
    @search="searchChange"
    :loading="loading.load"
    :page-num="pageNum"
    :total="total"
    :page-size="pageSize"
    @page-size-change="onPageSizeChange"
  >
    <template slot="modal">
      <fm-modal :value="newData !== null" width="80vw" :title="'药品' + (newData && newData.id) ? '修改' : '新增'" @cancel="newData = null" :maskClosable="false">
        <MedicineForm :data="newData" @submit="submit" />
      </fm-modal>
    </template>
  </PageBase>
</template>

<script>
import { medicineRequest } from '@/api'
import { types, getPackingName } from './lib'
import MedicineForm from './form'
import PageBase from '@/components/page/base'

export default {
  components: { MedicineForm, PageBase },
  props: {
    choose: { type: Boolean, default: false }
  },
  data () {
    return {
      type: types[0].value,
      types: types.map(v => {
        return { label: v.label, value: v.value }
      }).concat({
        label: '全部',
        value: '全部'
      }),
      loading: {
        load: false
      },
      newData: null,
      datas: [],
      search: {},
      pageSize: 10,
      pageNum: 1,
      total: 0
    }
  },
  computed: {
    tableColumns () {
      const type = this.type
      const action = { title: '操作', field: 'actions', slot: 'actions', fixed: 'right', search: false, export: false, width: 100, configurable: false }
      const columns = [
        { title: '名称', field: 'name', fixed: 'left' },
        { title: '编号', field: 'code', fixed: 'left' },
        { title: '类型', field: 'type', fixed: 'left' },
        { title: '别名', field: 'otherName', fixed: 'left' },
        { title: '生产厂家', field: 'manufacturer' },
        { title: getPackingName(type), field: 'packing' },
        { title: '药品简介', field: 'summary' },
        { title: '保质期', field: 'shelfLife' },
        { title: '零售价', field: 'price' },
        { title: '性', field: 'nature' },
        { title: '味', field: 'taste' },
        { title: '归经', field: 'guijing' },
        { title: '用药禁忌', field: 'contraindications' },
        { title: '注意事项', field: 'precautions' },
        { title: '功能主治', field: 'efficacy' },
        { title: '经验', field: 'experience' },
        { title: '不良反应', field: 'adverseReactions' },
        { title: '储藏要求', field: 'storageRequirements' },
        { title: '炮制方法', field: 'processingMethod' },
        { title: '功效分类', field: 'efficacyType' },
        { title: '用药频率', field: 'frequency' },
        { title: '类别（OTO）', field: 'typeOto' },
        { title: '批准文号', field: 'approvalNumber' },
        { title: '本位码', field: 'nativeCode' },
        {
          title: '是否拆零', field: 'detachable', render (h, row) {
            return h('div', row.detachable ? '可拆零' : '不可拆')
          }
        },
        { title: '剂型', field: 'dosageForm' },
        { title: '药品用法', field: 'instructions' },
        { title: '单位', field: 'unit' },
        { title: '单次剂量', field: 'singleDose' },
        { title: '净重', field: 'weight' },
        { title: '当量', field: 'equivalent' },
        { title: '当量单位', field: 'equivalentUnit' }
      ]

      const current = types.find(v => v.value === type)
      
      if (current) {
        return current.fields.map(v => {
          return columns.find(c => c.field === v)
        }).concat(action)
      } else {
        return columns.concat(action)
      }
    },
    tableActions () {
      return !this.choose ? [
        { key: 'update', label: '修改' },
        { key: 'del', label: '删除' }
      ] : [
        { key: 'choose', label: '选取' }
      ]
    },
    pageActions () {
      return [{label: '新增药品', value: 'add'}]
    },
    filters () {
      return [
        { label: '编号', field: 'code', show: true },
        { label: '名称', field: 'name', show: true },
        { label: '别名', field: 'otherName', show: false },
        { label: '生产厂家', field: 'manufacturer', show: false }
      ]
    },
    dataList () {
      return this.datas
    }
  },
  watch: {
    type () {
      this.loadData()
    }
  },
  methods: {
    onTabSwitch (type) {
      this.type = type
    },
    pageAction (key) {
      if (key === 'add') {
        this.newData = { type: this.type === '全部' ? null : this.type }
      }
    },
    searchChange (data) {
      this.search = data
      this.loadData()
    },
    tableAction ({action, data}) {
      if (action === 'update') {
        this.newData = data
      } else if (action === 'del') {
        this.del(data)
      } else if (action === 'choose') {
        this.$emit('choose', data)
      }
    },
    async del (data) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + data.name + '吗?'})
      if (result) {
        await medicineRequest.del(data.id)
        this.$notice.success('已删除')
        this.loadData()
      }
    },
    async submit (data) {
      if (data.id) {
        await medicineRequest.update(data.id, data)
        this.$notice.success('更新成功')
      } else {
        await medicineRequest.add(data)
        this.$notice.success('添加成功')
      }
      this.loadData()
      this.newData = null
    },
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    async loadData () {
      this.loading.load = true
      const res = await medicineRequest.get(Object.assign({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: this.type === '全部' ? undefined : this.type
      }, this.search))
      this.datas = res.data
      this.total = res.total
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData() 
  }
}
</script>
