<style scoped>
</style>

<template>
  <div class="medicine-form">
    <fm-form labelAlign="left" :inline="3" ref="form">
      <fm-form-item label="名称">
        <fm-input-new placeholder="请输入名称" v-model="newData.name"/>
      </fm-form-item>
      <fm-form-item label="编号">
        <fm-input-new placeholder="请输入编号" v-model="newData.code"/>
      </fm-form-item>
      <fm-form-item label="类型">
        <fm-select placeholder="请选择类型" clearable filterable v-model="newData.type">
          <fm-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="别名">
        <fm-input-new placeholder="请输入别名" v-model="newData.otherName" />
      </fm-form-item>
      <fm-form-item label="生产厂家">
        <fm-input-new placeholder="请输入生产厂家" v-model="newData.manufacturer" />
      </fm-form-item>
      <fm-form-item label="药品简介">
        <fm-input-new type="textarea" placeholder="请输入药品简介" v-model="newData.summary" />
      </fm-form-item>
      <fm-form-item label="保质期">
        <fm-input-new placeholder="请输入保质期" v-model="newData.shelfLife" />
      </fm-form-item>
      <fm-form-item label="零售价">
        <fm-input-new placeholder="请输入零售价" v-model="newData.price" />
      </fm-form-item>
      <fm-form-item label="性">
        <fm-input-new placeholder="请输入性" v-model="newData.nature" />
      </fm-form-item>
      <fm-form-item label="味">
        <fm-input-new placeholder="请输入味" v-model="newData.taste" />
      </fm-form-item>
      <fm-form-item label="归经">
        <fm-input-new placeholder="请输入归经" v-model="newData.guijing" />
      </fm-form-item>
      <fm-form-item label="用药禁忌">
        <fm-input-new type="textarea" placeholder="请输入用药禁忌" v-model="newData.contraindications" />
      </fm-form-item>
      <fm-form-item label="注意事项">
        <fm-input-new type="textarea" placeholder="请输入注意事项" v-model="newData.precautions" />
      </fm-form-item>
      <fm-form-item label="功能主治">
        <fm-input-new type="textarea" placeholder="请输入功能主治" v-model="newData.efficacy" />
      </fm-form-item>
      <fm-form-item label="经验">
        <fm-input-new type="textarea" placeholder="请输入经验" v-model="newData.experience" />
      </fm-form-item>
      <fm-form-item label="不良反应">
        <fm-input-new type="textarea" placeholder="请输入不良反应" v-model="newData.adverseReactions" />
      </fm-form-item>
      <fm-form-item label="储藏要求">
        <fm-input-new type="textarea" placeholder="请输入储藏要求" v-model="newData.storageRequirements" />
      </fm-form-item>
      <fm-form-item label="炮制方法">
        <fm-input-new type="textarea" placeholder="请输入炮制方法" v-model="newData.processingMethod" />
      </fm-form-item>
      <fm-form-item label="功效分类">
        <fm-input-new placeholder="请输入功效分类" v-model="newData.efficacyType" />
      </fm-form-item>
      <fm-form-item label="用药频率">
        <fm-input-new placeholder="请输入用药频率" v-model="newData.frequency" />
      </fm-form-item>
      <fm-form-item :label="packingName">
        <fm-input-new :placeholder="'请输入' + packingName" v-model="newData.packing" />
      </fm-form-item>
      <fm-form-item label="类别（OTO）">
        <fm-input-new placeholder="请输入类别（OTO）" v-model="newData.typeOto" />
      </fm-form-item>
      <fm-form-item label="批准文号">
        <fm-input-new placeholder="请输入批准文号" v-model="newData.approvalNumber" />
      </fm-form-item>
      <fm-form-item label="本位码">
        <fm-input-new placeholder="请输入本位码" v-model="newData.nativeCode" />
      </fm-form-item>
      <fm-form-item label="是否拆零">
        <fm-radio-group name="detachable" v-model="newData.detachable">
          <fm-radio :value="false" label="不可拆"></fm-radio>
          <fm-radio :value="true" label="可拆零"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="性状">
        <fm-select placeholder="请选择性状" clearable filterable v-model="newData.dosageForm">
          <fm-option v-for="item in dosageForm" :key="item.value" :label="item.label" :value="item.value"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="药品用法">
        <fm-input-new type="textarea" placeholder="请输入药品用法" v-model="newData.instructions" />
      </fm-form-item>
      <fm-form-item label="单位">
        <fm-input-new placeholder="请输入单位" v-model="newData.unit" />
      </fm-form-item>
      <fm-form-item label="单次剂量">
        <fm-input-new placeholder="请输入单次剂量" v-model="newData.singleDose" />
      </fm-form-item>
      <fm-form-item label="净重">
        <fm-input-new v-verifier :rules="[{type: 'number'}]" placeholder="请输入净重" v-model="newData.weight" />
      </fm-form-item>
      <fm-form-item label="当量规格">
        <fm-input-new v-verifier :rules="[{type: 'number'}]" placeholder="请输入当量规格" v-model="newData.equivalent" />
      </fm-form-item>
      <fm-form-item label="当量单位" v-if="['中药颗粒袋装', '中药饮片', '中药颗粒瓶装'].includes(newData.type)">
        <fm-input-new placeholder="请输入当量单位" v-model="newData.equivalentUnit" />
      </fm-form-item>
    </fm-form>
    <div style="display: flex;justify-content: flex-end;">
      <fm-btn long @click="submit">确定</fm-btn>
    </div>
  </div>
</template>

<script>
import { types, dosageForm, getPackingName } from './lib'

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      newData: this.data ? JSON.parse(JSON.stringify(this.data)) : {}
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.newData = this.data ? JSON.parse(JSON.stringify(this.data)) : {}
      }
    }
  },
  computed: {
    types () {
      return types
    },
    dosageForm () {
      return dosageForm
    },
    packingName () {
      return getPackingName(this.newData.type)
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.verifier()) {
        this.$emit('submit', this.newData)
      }
    }
  },
}
</script>
