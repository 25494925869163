/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Mon Nov 08 2021
 */

export function getPackingName (type) {
    if (['中西成药', '输液'].includes(type)) {
        return '包装规格'
    } else if (['中药颗粒袋装', '中药饮片', '中药颗粒瓶装'].includes(type)) {
        return '药品规格'
    } else {
        return '规格'
    }
}

export const types = Object.freeze([
    {
        label: '中药颗粒袋装', value: '中药颗粒袋装', cate: '袋装', action: 'bags', fields: [
            'code', 'name', 'otherName', 'manufacturer', 'packing', 'summary', 'shelfLife', 'price', 'nature', 'taste', 'guijing', 'contraindications', 'precautions', 'efficacy', 'experience', 'adverseReactions', 'storageRequirements', 'processingMethod', 'efficacyType', 'unit', 'equivalent', 'equivalentUnit'
        ]
    },
    {
        label: '中药饮片', value: '中药饮片', cate: '饮片', action: 'slices', fields: [
            'code', 'name', 'otherName', 'manufacturer', 'packing', 'summary', 'shelfLife', 'price', 'nature', 'taste', 'guijing', 'contraindications', 'precautions', 'efficacy', 'experience', 'adverseReactions', 'storageRequirements', 'processingMethod', 'efficacyType', 'unit', 'equivalent', 'equivalentUnit'
        ]
    },
    {
        label: '中药颗粒瓶装', value: '中药颗粒瓶装', cate: '瓶装', action: 'bottled', fields: [
            'code', 'name', 'otherName', 'manufacturer', 'packing', 'summary', 'shelfLife', 'price', 'nature', 'taste', 'guijing', 'contraindications', 'precautions', 'efficacy', 'experience', 'adverseReactions', 'storageRequirements', 'processingMethod', 'efficacyType', 'weight', 'unit', 'equivalent', 'equivalentUnit'
        ]
    },
    {
        label: '中西成药', value: '中西成药', cate: '成药', action: 'medicine', fields: [
            'code', 'name', 'otherName', 'manufacturer', 'packing', 'summary', 'shelfLife', 'price', 'nature', 'taste', 'guijing', 'contraindications', 'precautions', 'efficacy', 'experience', 'adverseReactions', 'storageRequirements', 'processingMethod', 'efficacyType', 'frequency', 'typeOto', 'approvalNumber', 'nativeCode', 'detachable', 'dosageForm', 'instructions', 'unit', 'singleDose'
        ]
    },
    {
        label: '输液', value: '输液', cate: '输液', action: 'infusion', fields: [
            'code', 'name', 'otherName', 'manufacturer', 'packing', 'summary', 'shelfLife', 'price', 'nature', 'taste', 'guijing', 'contraindications', 'precautions', 'efficacy', 'experience', 'adverseReactions', 'storageRequirements', 'processingMethod', 'efficacyType', 'frequency', 'typeOto', 'approvalNumber', 'nativeCode', 'detachable', 'dosageForm', 'instructions', 'unit', 'singleDose'
        ]
    }
]);

export const usageFrequency = ['一日一剂 分二次开水冲服', '一日一次', '遵医嘱服用', '一日两次', '一日三次', '2小时一次', '4小时一次', '6小时一次', '8小时一次', '睡前一次', '两天一次', '三天一次', '七天一次', '28天一次', '30天一次', '必要时服用', '一日一剂 分三次服', '二日一剂 分四次服', '三日两剂 分六次服', '一日一剂 分两次服']

export const usage = ['入壶', '冲管', '口服输液', '动脉注射', '肌内注射', '静脉注射', '静脉滴注', '静脉推注', '皮下注射', '皮内注射', '灌注', '雾化', '其他用法']

export const demand = ['水煎400ml；分早晚两次空腹温服', '水煎400ml；分早晚两次饭前温服', '水煎400ml；分早晚两次饭后温服', '水煎400ml；分早晚凉服', '水煎400ml；1日3次空腹温服', '水煎400ml；1日3次饭前温服', '水煎400ml；1日3次饭后温服', '水煎250ml；1日3次饭后温服']

export const doctorAdvice = ['不适随诊', '多饮水', '清淡饮食', '注意休息', '低盐低脂饮食', '无盐饮食', '普食', '流汁饮食', '低嘌呤饮食', '少渣饮食', '软食', '忌烟忌酒', '高热量饮食', '高蛋白饮食', '糖尿病饮食', '肾炎饮食', '禁食', '多活动', '卧床休息', '随意活动', '忌辛辣']

export const dosageForm = Object.freeze('胶囊型、颗粒、丸剂、片剂、软膏、乳膏、贴膏、口服液、混悬滴剂、干混悬剂、膏、喷剂、滴眼用、洗液、栓剂、含片、糖浆、合剂、搽-剂、凝胶、栓、冻干粉剂、皮酊、粉剂、滴剂、水剂、贴剂、颗粒剂'.split('、').map(v => {
    return {
        label: v, value: v
    }
}))

export const drippingSpeed = [
    { label: '滴/分钟', value: '滴/分钟' },
    { label: 'ug/分钟', value: 'ug/分钟' },
    { label: 'ml/小时', value: 'ml/小时' },
    { label: 'ml/分钟', value: 'ml/分钟' },
    { label: 'g/分钟', value: 'g/分钟' }
]

export const measurement = ['支', '包', '个', '台', '贴', '盒', '克', '毫克', '毫升', '瓶']